<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" page_title="inbox" />

    <v-container fluid>
      <!-- New UI -->
      <v-card class="rounded-xl pa-6">
        <v-row justify="space-between">
          <v-col cols="12" lg="3">
            <v-text-field
              outlined
              dense
              hide-details
              style="border-radius: 8px; border-color: #c0c0c0"
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="7" style="text-align: right !important">
            <v-btn
              class="text-capitalize"
              style="
                color: #424242;
                font-size: 16px;
                border: 1px solid #c0c0c0;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              @click="showFilterTools = !showFilterTools"
            >
              <v-icon class="mr-2" :small="true">mdi-tune-variant</v-icon>
              {{ $t("filter") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="showFilterTools">
          <v-col style="margin-top: 14px" lg="3" class="py-0">
            <v-row>
              <v-col cols="12" lg="6">
                <label
                  for="startdate"
                  style="
                    font-size: 16px;
                    color: #424242;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("startdate") }}</label
                >
                <v-menu
                  v-model="startmenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startdate"
                      prepend-inner-icon="mdi-calendar-blank-outline"
                      outlined
                      dense
                      readonly
                      placeholder="yyyy/mm/dd"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @change="OnChangeSDate()"
                    v-model="startdate"
                    @input="startDateDis()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="6">
                <label
                  for="enddate"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("enddate") }}</label
                >
                <v-menu
                  v-model="endmenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="enddate"
                      prepend-inner-icon="mdi-calendar-blank-outline"
                      outlined
                      dense
                      readonly
                      placeholder="yyyy/mm/dd"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @change="OnChangeSDate()"
                    v-model="enddate"
                    @input="endDateDis(), (endmenu = false)"
                    :allowed-dates="allowedDatesEnd"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="4" style="padding-top: 2.2rem">
            <v-btn
              text
              class="text-capitalize mt-1"
              height="40"
              style="
                border: 1px solid #c0c0c0;
                border-radius: 8px;
                color: #424242;
                font-size: 16px;
              "
              @click="clearAll()"
              >{{ $t("clearall") }}</v-btn
            >
            <v-btn
              width="120"
              height="40"
              class="text-capitalize btn_hover_effect ml-5 mt-1"
              style="
                color: #ffff;
                border: 1px solid #a6cc39;
                border-radius: 8px;
                font-size: 16px;
              "
              color="#A6CC39"
              @click="GetActivityReport()"
              >{{ $t("search") }}</v-btn
            >
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-5">
          <v-col cols="6" v-for="n in 6" :key="n">
            <v-skeleton-loader
              class="mx-auto"
              max-height="100"
              type="image"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <template>
            <v-col
              cols="12"
              lg="6"
              sm="12"
              v-for="(item, index) in inboxData"
              :key="index"
              :items="inboxData"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
            >
              <v-card
                class="rounded-lg"
                :search="search"
                :style="[
                  item.watchFlag == false
                    ? { 'background-color': '#e8f7dc' }
                    : { 'background-color': '#fff' },
                ]"
                @click="watchInboxNotiData(item)"
              >
                <v-card-title
                  v-model="searchtitle"
                  @input="searchInLists(), (searchtype = 'title')"
                  style="height: 100px;align-items: start;"
                >
                  <span
                    class="ml-0 truncated-text-2line"
                    style="font-size: 20px; color: #4d4f5c; cursor: pointer"
                    @click="watchInboxNotiData(item)"
                    >{{
                      item.message
                    }}</span
                  >
                  <!-- <v-spacer></v-spacer>
                  <span style="font-size: 18px; color: #a6cc39">{{
                    item.date
                  }}</span> -->
                </v-card-title>
                <v-card-text>
                  <span style="font-size: 18px; color: #a6cc39">{{
                    item.date
                  }}</span>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-card>
      <!-- end of New UI -->
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

export default {
  data: () => ({
    search: "",
    groupCopy: [],
    searchtype: null,
    searchtitle: null,
    searchuser: null,
    showFilterTools: false,
    noti_count: 0,
    dpSDate: "",
    dpEDate: "",
    dateoffset: new Date().getTimezoneOffset(),
    loading: true,
    breadcrumbs: [
      {
        sidebar_tilte: "home",
      },
      {
        text: "inbox",
      },
    ],
    startdate: "", //new Date().toISOString().substr(0, 10),
    enddate: "", //new Date().toISOString().substr(0, 10),
    startmenu: false,
    endmenu: false,
    inboxData: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
  }),

  computed: {
    headers() {
      return [
        {
          // text: this.$t("abbre"),
          align: "left",
          value: "username",
          //width: "350px",
        },
        {
          // text: this.$t("abbre"),
          align: "left",
          value: "title",
          //width: "350px",
        },
        {
          // text: this.$t("abbre"),
          align: "left",
          value: "date",
          //width: "350px",
        },
      ];
    },
  },

  mounted() {
    this.getInboxNotificationDataCount();
    this.getInboxNotificationData(1);
    this.loop();
  },

  methods: {
    clearAll() {
      this.enddate = null;
      this.startdate = null;
      this.search = "";
    },
    searchInLists() {
      switch (this.searchtype) {
        case "title":
          if (!this.searchtitle) {
            this.title = this.groupCopy;
          }

          this.title =
            this.searchtitle != null
              ? this.groupCopy.filter((x) => {
                  return (
                    x.groupName
                      .toLowerCase()
                      .indexOf(this.searchtitle.toLowerCase()) > -1
                  );
                })
              : this.groupCopy;
          break;
        case "user":
          if (!this.searchuser) {
            this.useritems = this.userCopy;
          }

          this.useritems =
            this.searchuser != null
              ? this.userCopy.filter((x) => {
                  return (
                    x.fullName
                      .toLowerCase()
                      .indexOf(this.searchuser.toLowerCase()) > -1
                  );
                })
              : this.userCopy;
          break;
        default:
          console.log("Searched...");
      }
    },
    async getInboxNotificationDataCount() {
      let that = this;
      const request = {
        companyId: localStorage.getItem("companyID"),
        startDate: "",
        endDate: "",
        offset: that.dateoffset,
        userId: parseInt(localStorage.getItem("UserID")),
      };
      let response =await axios.post(
        `${that.web_urlV3}Notification/GetNotificationFromWeb`,
        request
      );
      if (response.data.data) {
        that.noti_count =
          response.data.data.filter((x) => x.watchFlag === false).length;
      }
      that.$emit("updateNotiCount", that.noti_count);
    },
    async watchInboxNotiData(item) {
      let that = this;
      let response = null;
      let request = null;

      request = {
        companyId: localStorage.getItem("companyID"),
        userID: parseInt(localStorage.getItem("UserID")),
        feedbackOrQuesionID: item.feedbackOrQuestionID,
        watchFlag: true,
        checkFeedbackOrQuestion: item.separatePage//item.type == "question" ? 1 : 2,
      };
      response = await axios.post(
        `${that.web_url}Notification/AddNotificationFromWeb`,
        request
      );
      let noti_temp =
        that.noti_count != 0 && that.noti_count > 0 ? that.noti_count - 1 : 0;

      if (item.watchFlag === false) {
        this.$emit("updateNotiCount", noti_temp);
      }

      if (response.data.status == 0) {
        console.log("Success");
      }
      if (item.separatePage == 1) {
        that.$router.push("/home/community/expert-consultant");
      } else if(item.separatePage == 2){
        that.$router.push("/home/feedback-box");
      }
    },

    OnChangeSDate() {
      this.dpSDate = this.startdate + " 00:00";
      this.dpEDate = this.enddate + " 23:59";

      if (this.dpSDate != null && this.dpEDate != null) {
        this.getInboxNotificationData(2);
      }
    },
    async getInboxNotificationData(type) {
      let that = this;
      let request = null;
      if (type == 2) {
        request = {
          companyId: localStorage.getItem("companyID"),
          startDate: that.dpSDate,
          endDate: that.dpEDate,
          offset: that.dateoffset,
          userId: parseInt(localStorage.getItem("UserID")),
        };
      } else {
        request = {
          companyId: localStorage.getItem("companyID"),
          startDate: "",
          endDate: "",
          offset: that.dateoffset,
          userId: parseInt(localStorage.getItem("UserID")),
        };
      }
      await axios.post(
        `${that.web_urlV3}Notification/GetNotificationFromWeb`,
        request
      ).then(function(response){
        that.inboxData = response.data.data.map((v) => ({
          ...v,
          message: that.MessageForNoti(v),
          date : moment(v.sendDate).local().format("DD/MM/YYYY") +
            " " +
            moment(v.sendDate).local().format("hh:mm A")
        }));
      });
      // if (response.data.data || response.data.data1) {
      //   let feedback = response.data.data.map((v) => ({
      //     ...v,
      //     type: "feedback",
      //     date:
      //       moment(v.sendDate).local().format("DD/MM/YYYY") +
      //       " " +
      //       moment(v.sendDate).local().format("hh:mm A"),
      //   }));
      //   let question = response.data.data1.map((v) => ({
      //     ...v,
      //     type: "question",
      //     date:
      //       moment(v.sendDate).local().format("DD/MM/YYYY") +
      //       " " +
      //       moment(v.sendDate).local().format("hh:mm A"),
      //   }));

      //   that.inboxData = question.concat(feedback);
      //   that.inboxData.sort(
      //     (a, b) => new Date(b.sendDate) - new Date(a.sendDate)
      //   );
      // }
      this.loading = false;
    },

    CommentMessage(item){
      let that = this;
      let returnmessage;
      let secondmessage;
      let commentnotificationmessage = that.$t("commentnotificationmessage");
      let coursecommentnotificationmessage = that.$t("coursecommentnotificationmessage");
      let replycommentnotificationmessage = that.$t("replycommentnotificationmessage");
      let coursereplycommentnotificationmessage = that.$t("coursereplycommentnotificationmessage");
      let article = that.$t("article");
      let sound = that.$t("sound");
      let video = that.$t("video");
      let knowledgesharing = that.$t("lessonlearn");

      if(item.separatePage == 3){
          switch(item.page){
                case 'Article':
                  secondmessage = commentnotificationmessage.replace("{0}", article);
                  returnmessage = secondmessage.replace("{1}", item.title);
                  break;
                case 'Sound':
                   secondmessage = commentnotificationmessage.replace("{0}", sound);
                  returnmessage = secondmessage.replace("{1}", item.title);
                  break;
                case 'Video':
                   secondmessage = commentnotificationmessage.replace("{0}", video);
                  returnmessage = secondmessage.replace("{1}", item.title);
                  break;
                case 'Knowledge Sharing':
                   secondmessage = commentnotificationmessage.replace("{0}", knowledgesharing);
                  returnmessage = secondmessage.replace("{1}", item.title);
                  break;
                default:
                   secondmessage = coursecommentnotificationmessage.replace("{0}", item.page);
                   returnmessage = secondmessage.replace("{1}", item.title);
                  break;
              }
      }else{
          switch(item.page){
                case 'Article':
                  secondmessage = replycommentnotificationmessage.replace("{0}", article);
                  returnmessage = secondmessage.replace("{1}", item.title);
                  break;
                case 'Sound':
                   secondmessage = replycommentnotificationmessage.replace("{0}", sound);
                  returnmessage = secondmessage.replace("{1}", item.title);
                  break;
                case 'Video':
                   secondmessage = replycommentnotificationmessage.replace("{0}", video);
                  returnmessage = secondmessage.replace("{1}", item.title);
                  break;
                case 'Knowledge Sharing':
                   secondmessage = replycommentnotificationmessage.replace("{0}", knowledgesharing);
                  returnmessage = secondmessage.replace("{1}", item.title);
                  break;
                default:
                   secondmessage = coursereplycommentnotificationmessage.replace("{0}", item.page);
                   returnmessage = secondmessage.replace("{1}", item.title);
                  break;
              }
          }
        
              return returnmessage;
    },

    MessageForNoti(item){
      let that = this;
      let returnmessage;
       switch (item.separatePage){
        case 2:
          returnmessage = that.$t("sendfeedbackpleaseread");
          break;
        case 3:
          returnmessage = that.CommentMessage(item);
          break;
        case 4:
          returnmessage = that.CommentMessage(item);
          break;
          default:
            returnmessage = that.$t("addquestionpleaseread");
            break;
       }
       return item.username+' '+returnmessage;
    },

    startDateDis() {
      this.startmenu = false;
      if (this.enddate == "") {
        this.enddate = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
      if (this.startdate > this.enddate) {
        this.enddate = this.startdate;
      }
    },
    endDateDis() {
      if (this.startdate == "") {
        this.startdate = this.enddate;
      }
    },
    allowedDatesEnd(val) {
      return val >= this.startdate;
    },
    loop() {
      setTimeout(() => {
        // Your logic here
        if (this.startdate && this.enddate) {
          this.getInboxNotificationData(2);
        } else {
          this.getInboxNotificationData(1);
        }
        this.getInboxNotificationDataCount();
        this.loop();
      }, 2000);
    },
  },
};
</script>

<style scoped>
.truncated-text-2line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;  
  cursor: pointer;
  white-space: pre-line;
}
::v-deep .pagination .primary {
  background-color: #e8e9ec !important;
  color: #4d4f5c !important;
  opacity: 1;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: unset;
}
</style>
